exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adobe-jsx": () => import("./../../../src/pages/adobe.jsx" /* webpackChunkName: "component---src-pages-adobe-jsx" */),
  "component---src-pages-autodesk-jsx": () => import("./../../../src/pages/autodesk.jsx" /* webpackChunkName: "component---src-pages-autodesk-jsx" */),
  "component---src-pages-compliance-jsx": () => import("./../../../src/pages/compliance.jsx" /* webpackChunkName: "component---src-pages-compliance-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-obrigado-jsx": () => import("./../../../src/pages/obrigado.jsx" /* webpackChunkName: "component---src-pages-obrigado-jsx" */),
  "component---src-pages-outros-softwares-jsx": () => import("./../../../src/pages/outros-softwares.jsx" /* webpackChunkName: "component---src-pages-outros-softwares-jsx" */),
  "component---src-pages-produtos-jsx": () => import("./../../../src/pages/produtos.jsx" /* webpackChunkName: "component---src-pages-produtos-jsx" */),
  "component---src-pages-solucoes-jsx": () => import("./../../../src/pages/solucoes.jsx" /* webpackChunkName: "component---src-pages-solucoes-jsx" */)
}

